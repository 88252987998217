import {
  FC,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { FinancialForm, FinancialFormInputContainer } from "../financial";
import {
  FormErrors,
  InputDate,
  InputNumber,
  InputSelect,
  InputSwitch,
  Label,
  Option,
  useForm,
} from "../components/form";
import { Dollar, Percent } from "../icons";
import {
  LiabilityTypeCreditCardsData,
  LiabilityTypeLiabilitiesData,
  ILiability,
  LendersData,
} from ".";
import { isNull } from "lodash";
import { Frequency, IApplicant } from "../applicant";
import { IModelId, useStore } from "..";
import { format, parse } from "date-fns";
import { toFixed } from "../util";

type Category = "Credit Cards" | "Liabilities";

type Value = Omit<ILiability, "_id" | "LoanId"> & {
  InterestOnlyTermYears?: number | null;
  TotalTermYears?: number | null;
};

type Inputs = {
  [K in keyof Value]?: {
    required: boolean;
    type?: "text" | "number";
  };
};

const frequency: {
  value: Frequency;
  label: string;
}[] = [
  {
    value: "Yearly",
    label: "Yearly",
  },
  {
    value: "HalfYearly",
    label: "Half-yearly",
  },
  {
    value: "Quarterly",
    label: "Quarterly",
  },
  {
    value: "Monthly",
    label: "Monthly",
  },
  {
    value: "Fortnightly",
    label: "Fortnightly",
  },
  {
    value: "Weekly",
    label: "Weekly",
  },
];

const LiabilityForm: FC<{
  applicantPartyId: IModelId;
  header?: string;
  initialValue?: Value;
  category: Category;
  onSave: (value: Value) => void;
  onCancel: () => void;
}> = ({
  header,
  initialValue,
  category,
  onSave,
  onCancel,
  applicantPartyId,
}) => {
  const applicants = useStore(
    useCallback(
      (state) => state.ApplicantParties.get(applicantPartyId)?.Applicant,
      [applicantPartyId]
    )
  );
  const ApplicantId =
    applicants && applicants.size === 1
      ? applicants.get("applicant")?.ApplicantId
      : null;

  const [inputs, setInputs] = useState<Inputs>({
    RepaymentAmount: {
      type: "number",
      required: true,
    },
    RepaymentFrequency: {
      required: true,
    },
    Balance: {
      type: "number",
      required: true,
    },
  });

  const initialValueRef = useRef<Value>(
    initialValue
      ? {
          ...initialValue,
          InterestRate: initialValue.InterestRate
            ? toFixed(initialValue.InterestRate * 100)
            : null,
          TotalTermYears: Math.floor((initialValue.TotalTermMonths || 0) / 12),
          TotalTermMonths: (initialValue.TotalTermMonths || 12) % 12,
          InterestOnlyTermYears: Math.floor(
            (initialValue.InterestOnlyTermMonths || 0) / 12
          ),
          InterestOnlyTermMonths:
            (initialValue.InterestOnlyTermMonths || 12) % 12,
        }
      : {
          ApplicantId: ApplicantId || null,
          LiabilityType: null,
          RepaymentAmount: null,
          RepaymentFrequency: "Monthly",
          Limit: null,
          Balance: null,
          InterestRate: null,
          RepaymentType: null,
          StartDate: null,
          TotalTermMonths: null,
          InterestOnlyTermMonths: null,
          Lender: null,
          IsRevolvingCredit: null,
        }
  );

  const { values, handleChange, handleSubmit, hasErrors } = useForm<Value>({
    initialValues: initialValueRef.current,
    validate: (values) => {
      const errors: FormErrors<Value> = {};

      Object.keys(inputs).forEach((key) => {
        const objectKey = key as keyof Inputs;

        if (inputs[objectKey]?.required) {
          if (inputs[objectKey]?.type === "number") {
            if (
              !values[objectKey] ||
              isNull(values[objectKey]) ||
              Number(values[objectKey]) <= 0
            ) {
              errors[objectKey] = `this field must be greater than zero`;
            }

            if (
              objectKey === "TotalTermYears" ||
              objectKey === "TotalTermMonths"
            ) {
              delete errors[objectKey];

              if (
                (values.TotalTermYears || 0) / 12 +
                  (values.TotalTermMonths || 0) <=
                0
              ) {
                errors[objectKey] = "This field is mandatory";
              }
            }
          } else {
            if (!values[objectKey]) {
              errors[objectKey] = `this field cannot be blank`;
            }
          }
        }
      });

      if (!values.LiabilityType) {
        errors.LiabilityType = "This field cannot be blank";
      }

      return errors;
    },
    validateOnMount: true,
    onSubmit: ({ TotalTermYears, InterestOnlyTermYears, ...res }, errors) => {
      const isFrequency = (freq?: string | null): freq is Frequency => {
        if (!freq || !frequency.find((f) => f.value === freq)) {
          return false;
        }
        return true;
      };

      let TotalTermMonths = res.TotalTermMonths;
      let InterestOnlyTermMonths = res.InterestOnlyTermMonths;

      if (TotalTermYears) {
        if (!TotalTermMonths) TotalTermMonths = 0;
        TotalTermMonths += TotalTermYears * 12;
      }

      if (InterestOnlyTermYears) {
        if (!InterestOnlyTermMonths) InterestOnlyTermMonths = 0;
        InterestOnlyTermMonths += InterestOnlyTermYears * 12;
      }

      const saveValues: Value = {
        ...res,
        RepaymentAmount: !errors.RepaymentAmount
          ? Number(res.RepaymentAmount)
          : initialValueRef.current.RepaymentAmount,
        Limit: !errors.Limit
          ? Number(res.Limit)
          : initialValueRef.current.Limit,
        Balance: !errors.Balance
          ? Number(res.Balance)
          : initialValueRef.current.Balance,
        InterestRate: !errors.InterestRate
          ? toFixed(Number(res.InterestRate) / 100)
          : initialValueRef.current.InterestRate,
        RepaymentFrequency:
          !errors.RepaymentFrequency && isFrequency(res.RepaymentFrequency)
            ? values.RepaymentFrequency
            : initialValueRef.current.RepaymentFrequency,
        TotalTermMonths: !errors.TotalTermMonths
          ? TotalTermMonths
          : initialValueRef.current.TotalTermMonths,
        InterestOnlyTermMonths: !errors.InterestOnlyTermMonths
          ? InterestOnlyTermMonths
          : initialValueRef.current.InterestOnlyTermMonths,
      };

      Object.keys(saveValues).forEach((key) => {
        const objKey = key as keyof Value;

        if (!inputs[objKey] && key !== "LiabilityType") {
          saveValues[objKey] = null;
        }
      });

      onSave(saveValues);
    },
  });

  const liabilityTypeOptions = useMemo(() => {
    if (category === "Credit Cards") {
      return LiabilityTypeCreditCardsData;
    }

    return LiabilityTypeLiabilitiesData;
  }, [category]);

  useEffect(() => {
    const creditCardsInputCondition = category === "Credit Cards";
    const allInputCondition: boolean =
      !isNull(values.LiabilityType) &&
      ["HomeLoan", "LineOfCredit", "LoanAsGuarantor", "Mortgage"].includes(
        values.LiabilityType
      );
    const almostAllInputCondition: boolean =
      !isNull(values.LiabilityType) &&
      ["BusinessLoan", "CarLoan", "PersonalLoan", "Other"].includes(
        values.LiabilityType
      );
    const overdraftInputCondition: boolean =
      values.LiabilityType === "Overdraft";
    const studentLoanCondition: boolean =
      values.LiabilityType === "StudentLoan";
    const buyNowPayLaterCondition = values.LiabilityType === "BuyNowPayLater";

    let newInputs: Inputs = {
      RepaymentAmount: {
        type: "number",
        required: true,
      },
      RepaymentFrequency: {
        required: true,
      },
      Balance: {
        type: "number",
        required: true,
      },
    };

    if (buyNowPayLaterCondition) {
      newInputs = {
        RepaymentAmount: {
          type: "number",
          required: true,
        },
        RepaymentFrequency: {
          required: true,
        },
        Balance: {
          type: "number",
          required: true,
        },
        Limit: {
          type: "number",
          required: true,
        },
      };
    }

    if (creditCardsInputCondition) {
      newInputs = {
        Limit: {
          type: "number",
          required: true,
        },
        Lender: {
          required: false,
        },
      };
    } else if (allInputCondition) {
      newInputs = {
        RepaymentAmount: {
          type: "number",
          required: true,
        },
        RepaymentFrequency: {
          required: true,
        },
        Limit: {
          type: "number",
          required: false,
        },
        Balance: {
          type: "number",
          required: true,
        },
        Lender: {
          required: false,
        },
        RepaymentType: {
          required: false,
        },
        InterestRate: {
          type: "number",
          required: true,
        },
        StartDate: {
          required: false,
        },
        TotalTermYears: {
          type: "number",
          required: true,
        },
        TotalTermMonths: {
          type: "number",
          required: true,
        },
        InterestOnlyTermYears: {
          type: "number",
          required: false,
        },
        InterestOnlyTermMonths: {
          type: "number",
          required: false,
        },
        IsRevolvingCredit: {
          required: false,
        },
      };
    } else if (almostAllInputCondition) {
      newInputs = {
        RepaymentAmount: {
          type: "number",
          required: true,
        },
        RepaymentFrequency: {
          required: true,
        },
        Limit: {
          type: "number",
          required: false,
        },
        Balance: {
          type: "number",
          required: true,
        },
        Lender: {
          required: false,
        },
        InterestRate: {
          type: "number",
          required: true,
        },
        StartDate: {
          required: false,
        },
        TotalTermYears: {
          type: "number",
          required: true,
        },
        TotalTermMonths: {
          type: "number",
          required: true,
        },
        IsRevolvingCredit: {
          required: false,
        },
      };
    } else if (overdraftInputCondition) {
      newInputs = {
        RepaymentAmount: {
          type: "number",
          required: true,
        },
        RepaymentFrequency: {
          required: true,
        },
        Limit: {
          type: "number",
          required: true,
        },
        Balance: {
          type: "number",
          required: false,
        },
        InterestRate: {
          type: "number",
          required: false,
        },
      };
    } else if (studentLoanCondition) {
      newInputs = {
        ApplicantId: {
          required: true,
        },
        RepaymentAmount: {
          type: "number",
          required: true,
        },
        RepaymentFrequency: {
          required: true,
        },
        Balance: {
          type: "number",
          required: true,
        },
      };
    }

    setInputs(newInputs);
  }, [category, values.LiabilityType]);

  return (
    <FinancialForm
      header={header}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isValid={!hasErrors}
    >
      <FinancialFormInputContainer className="grid-cols-8">
        <Label htmlFor="liabilityType" className="required col-span-2">
          {category === "Liabilities" ? "Liability Type" : "Type"}
        </Label>
        <InputSelect
          initialValue={values.LiabilityType}
          onChange={(value) => handleChange("LiabilityType", value)}
          className="col-span-3"
          searchable={
            category === "Liabilities"
              ? { placeholder: "Search liability" }
              : false
          }
        >
          {liabilityTypeOptions.map((type) => (
            <Option
              value={type["LiabilityType.Code"]}
              key={type["LiabilityType.Code"]}
            >
              {type["LiabilityType.Name"]}
            </Option>
          ))}
        </InputSelect>
      </FinancialFormInputContainer>
      <ConditionalLiabilityForm
        applicants={applicants}
        values={values}
        handleChange={handleChange}
        inputs={inputs}
      />
    </FinancialForm>
  );
};

const ConditionalLiabilityForm: FC<{
  applicants?: Map<"applicant" | "spouse", IApplicant>;
  values: Value;
  handleChange: <Key extends keyof Value>(
    name: Key,
    payload: Value[Key]
  ) => void;
  inputs: Inputs;
}> = memo(({ values, handleChange, inputs, applicants }) => {
  return (
    <>
      {inputs.ApplicantId && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="ApplicantId"
            className={`col-span-2 ${
              inputs.ApplicantId.required && "required"
            }`}
          >
            Applicant
          </Label>
          <InputSelect
            initialValue={values.ApplicantId}
            onChange={(value) => handleChange("ApplicantId", value)}
            className="col-span-3"
          >
            {Array.from(applicants?.values() || []).map((applicant) => (
              <Option key={applicant.ApplicantId} value={applicant.ApplicantId}>
                {applicant.Name}
              </Option>
            ))}
          </InputSelect>
        </FinancialFormInputContainer>
      )}
      {inputs.RepaymentAmount && inputs.RepaymentFrequency && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="repayments"
            className={`col-span-2 ${
              inputs.RepaymentAmount.required && "required"
            }`}
          >
            Repayments
          </Label>
          <div className="col-span-3 flex items-center rounded-[3px] border border-[#C2B6CB]">
            <InputNumber
              id="RepaymentAmount"
              withIcon={<Dollar />}
              value={values.RepaymentAmount || undefined}
              className="rounded-none border-transparent border-r-[#C2B6CB] focus:!outline-none"
              onChange={(value) => {
                handleChange("RepaymentAmount", value);
              }}
              autonumeric
            />
            <InputSelect
              initialValue={values.RepaymentFrequency}
              onChange={(value) => handleChange("RepaymentFrequency", value)}
              className="w-[50px]"
              border="border-0"
              formatButtonLabel={(value) => value?.charAt(0)}
            >
              <Option value={null}></Option>
              {frequency.map((freq) => (
                <Option value={freq.value} key={freq.value}>
                  {freq.label}
                </Option>
              ))}
            </InputSelect>
          </div>
        </FinancialFormInputContainer>
      )}
      {inputs.Limit && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="Limit"
            className={`col-span-2 ${inputs.Limit.required && "required"}`}
          >
            Limit
          </Label>
          <InputNumber
            wrapperClassName="col-span-3"
            id="Limit"
            withIcon={<Dollar />}
            value={values.Limit || undefined}
            onChange={(value) => {
              handleChange("Limit", value);
            }}
            autonumeric
          />
        </FinancialFormInputContainer>
      )}
      {inputs.Balance && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="Balance"
            className={`col-span-2 ${inputs.Balance.required && "required"}`}
          >
            Balance
          </Label>
          <InputNumber
            id="Balance"
            withIcon={<Dollar />}
            value={values.Balance || undefined}
            onChange={(value) => {
              handleChange("Balance", value);
            }}
            wrapperClassName="col-span-3"
            autonumeric
          />
        </FinancialFormInputContainer>
      )}
      {inputs.Lender && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="Lender"
            className={`col-span-2 ${inputs.Lender.required && "required"}`}
          >
            Lender
          </Label>
          <InputSelect
            initialValue={values.Lender}
            onChange={(value) => handleChange("Lender", value)}
            className="col-span-3"
            searchable={{ placeholder: "Search Lender" }}
          >
            <Option value={null}></Option>
            {LendersData.map((lender) => (
              <Option value={lender.Code} key={lender.Code}>
                {lender.Name}
              </Option>
            ))}
          </InputSelect>
        </FinancialFormInputContainer>
      )}
      {inputs.RepaymentType && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="RepaymentType"
            className={`col-span-2 ${
              inputs.RepaymentType.required && "required"
            }`}
          >
            Repayment Type
          </Label>
          <InputSelect
            initialValue={values.RepaymentType}
            onChange={(value) => handleChange("RepaymentType", value)}
            className="col-span-3"
          >
            <Option value={null}></Option>
            {[
              {
                value: "PrincipalInterest",
                label: "Principal & Interest",
              },
              {
                value: "InterestOnly",
                label: "Interest-Only",
              },
            ].map((type) => (
              <Option value={type.value} key={type.value}>
                {type.label}
              </Option>
            ))}
          </InputSelect>
        </FinancialFormInputContainer>
      )}
      {inputs.InterestRate && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="InterestRate"
            className={`col-span-2 ${
              inputs.InterestRate.required && "required"
            }`}
          >
            Interest Rate
          </Label>
          <InputNumber
            id="InterestRate"
            wrapperClassName="col-span-3"
            withIcon={<Percent className="opacity-50" />}
            iconPosition="right"
            value={values.InterestRate || undefined}
            onChange={(value) => handleChange("InterestRate", value)}
          />
        </FinancialFormInputContainer>
      )}
      {inputs.StartDate && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="StartDate"
            className={`col-span-2 ${inputs.StartDate.required && "required"}`}
          >
            Start Date
          </Label>
          <InputDate
            id="StartDate"
            wrapperClassName="col-span-3"
            placeholder="DD / MM / YYYY"
            value={
              values.StartDate
                ? parse(values.StartDate, "yyyy-MM-dd", new Date())
                : undefined
            }
            onChange={(date) =>
              handleChange("StartDate", format(date, "yyyy-MM-dd"))
            }
          />
        </FinancialFormInputContainer>
      )}
      {inputs.TotalTermYears && inputs.TotalTermMonths && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="loanTerm"
            className={`col-span-2 ${
              inputs.TotalTermYears.required &&
              inputs.TotalTermMonths.required &&
              "required"
            }`}
          >
            Loan Term
          </Label>
          <div className="col-span-3 grid grid-cols-2 gap-2">
            <div className="relative">
              <InputNumber
                type="number"
                id="TotalTermYears"
                className="pr-10"
                placeholder="0"
                pattern="^[0-9]*$"
                value={values.TotalTermYears || undefined}
                onChange={(value) => {
                  handleChange("TotalTermYears", value);
                }}
              />
              <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                years
              </span>
            </div>
            <div className="relative">
              <InputNumber
                id="TotalTermMonths"
                className="pr-12"
                placeholder="0"
                value={values.TotalTermMonths || undefined}
                onChange={(value) => {
                  handleChange("TotalTermMonths", value);
                }}
                pattern="^[0-9]*$"
              />
              <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                months
              </span>
            </div>
          </div>
        </FinancialFormInputContainer>
      )}
      {inputs.InterestOnlyTermYears && inputs.InterestOnlyTermMonths && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="loanTermYears"
            className={`col-span-2 ${
              inputs.InterestOnlyTermYears.required &&
              inputs.InterestOnlyTermMonths.required &&
              "required"
            }`}
          >
            <span className="block w-[90%]">Interest-Only Term</span>
          </Label>
          <div className="col-span-3 grid grid-cols-2 gap-2">
            <div className="relative">
              <InputNumber
                id="InterestOnlyTermYears"
                className="pr-10"
                placeholder="0"
                value={values.InterestOnlyTermYears || undefined}
                onChange={(value) =>
                  handleChange("InterestOnlyTermYears", value)
                }
                pattern="^[0-9]*$"
              />
              <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                years
              </span>
            </div>
            <div className="relative">
              <InputNumber
                id="InterestOnlyTermMonths"
                className="pr-12"
                placeholder="0"
                value={values.InterestOnlyTermMonths || undefined}
                onChange={(value) =>
                  handleChange("InterestOnlyTermMonths", value)
                }
                pattern="^[0-9]*$"
              />
              <span className="pointer-events-none absolute right-2 top-1/2 -translate-y-1/2 font-helvetica text-[11px] text-[#6F5F7B]">
                months
              </span>
            </div>
          </div>
        </FinancialFormInputContainer>
      )}
      {inputs.IsRevolvingCredit && (
        <FinancialFormInputContainer className="grid-cols-8">
          <Label
            htmlFor="IsRevolvingCredit"
            className={`col-span-2 ${
              inputs.IsRevolvingCredit.required && "required"
            }`}
          >
            Revolving Credit / Redraw Facility
          </Label>
          <InputSwitch
            id="IsRevolvingCredit"
            className="col-span-3"
            value={values.IsRevolvingCredit}
            onChange={(value) => handleChange("IsRevolvingCredit", value)}
          >
            <InputSwitch.InputSwitchOption value={true}>
              Yes
            </InputSwitch.InputSwitchOption>
            <InputSwitch.InputSwitchOption value={false}>
              No
            </InputSwitch.InputSwitchOption>
          </InputSwitch>
        </FinancialFormInputContainer>
      )}
    </>
  );
});

export default memo(LiabilityForm);
